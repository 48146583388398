/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import "../assets/css/AlreadyLoggedIn.css";
import { parseJSON } from "../helpers/utils";

export default () => {
  const { currentUser, logout } = useAuth();
  console.log(localStorage.siteSettings)

  const proceedWithCurrentSession = () => {
    console.log(localStorage)
    let encodedData = parseJSON(localStorage.encodedDataVariance);
    let redirectUrl =localStorage.redirectUrl || "/dashboard?entry=";
    redirectUrl = `${redirectUrl}${encodedData}`;
    window.location.href = redirectUrl;
  };

  const destroyCurrentSession = async () => {
    alert("This action will log you out of your current session.")
    await logout();
    window.location.href = `/login/?entry=${localStorage.urlParam}`;
  };

  const renderAlreadyLoggedIn = () => {
    return (
      <div className="already-logged-in-container elevate-float">
        <div className="top-section-container">
          <h2>Sign In</h2>
          <p>
            as {currentUser?.email} <span onClick={()=>destroyCurrentSession()}>Change</span>
          </p>
        </div>

        <div className="already-signed-in-clarification">
            <p>You are currently logged in as <strong>{currentUser?.email}</strong>. Would you want to continue?</p>
        </div>

        <div className="continue-btn-container elevate-float" onClick={()=>proceedWithCurrentSession()}>
          <p>Continue </p>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "90vh",
      }}
    >
      {renderAlreadyLoggedIn()}
    </div>
  );
};
