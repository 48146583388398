import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
  TextLinkWrap,
  SocialMediaWrap,
  SocialIconLink,
  SocialIcons,
  FooterInfo,
} from "./styles/EmailLoginElements";

import { FaGoogle, FaEnvelope } from "react-icons/fa";
import { redirectCurrentUser } from "../helpers/authHelpers";
import { parseJSON } from "../helpers/utils";


export default function EmailLogin() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, googleSignin, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const titleText = "Email Login";
  const history = useHistory();
  const comingData = parseJSON(localStorage.dataGotten);
  const urlExtention = window.location.href.toString().split("?")[1];

  // const emailLoginUrl = `/phone-number-login/?${urlExtention}`
  const signUpUrl = `/signup/?${urlExtention}`;
 localStorage.redirectUrl =  JSON.parse(atob(urlExtention?.split("=")[1]))?.redirectTo


  if (currentUser) {
    redirectCurrentUser(currentUser)
  }

  const handleRedirect = (loginRes, method) => {
    if (loginRes.user) {
      const isSignIn = loginRes.operationType === "signIn";
      const newReturnBody = {
        userName: loginRes.user.displayName,
        refreshToken: loginRes.user.za,
        email: loginRes.user.email,
        phoneNumber: loginRes.user.phoneNumber,
        signIn: isSignIn,
        signUp: !isSignIn,
        actionType: method,
      };

      let loginResults = JSON.stringify(newReturnBody);
      const encodedData = btoa(loginResults);
      let storage = parseJSON(localStorage.dataGotten);
      let redirectUrl = storage.redirectTo;
      redirectUrl = `${redirectUrl}${encodedData}`;
      window.location.href = redirectUrl;
    } else {
      history.push("/");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const loginRes = await login(
        emailRef.current.value,
        passwordRef.current.value
      );
      handleRedirect(loginRes, "Email");
      // history.push("/");
    } catch {
      setError("Email or Password incorrect");
    }

    setLoading(false);
  }

  async function handleGoogleSignIn(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const loginRes = await googleSignin();
      handleRedirect(loginRes, "Gmail");
      // history.push("/");
    } catch (error) {
      console.log(error);
      setError("Google Sign In Failed");
    }

    setLoading(false);
  }

  // async function handleFbSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await fbSignin();
  //     handleRedirect(loginRes, "Facebook");
  //     // history.push("/");
  //   } catch (error) {
  //     setError("Facebook Sign In Failed");
  //   }

  //   setLoading(false);
  // }

  // async function handleTwitterSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await twitterSignin();
  //     handleRedirect(loginRes, "Twitter");
  //     // history.push("/");
  //   } catch (error) {
  //     setError("Twitter Sign In Failed");
  //   }

  //   setLoading(false);
  // }

  return (
    <>
      <Container>
        <Icon to="/">{comingData.appName}</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>{titleText}</FormH1>
              {error && <Text style={{ color: "red" }}>{error}</Text>}
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput type="email" ref={emailRef} required />
              <FormLabel htmlFor="for">Password</FormLabel>
              <FormInput type="password" ref={passwordRef} required />
              <FormButton type="submit" disabled={loading}>
                Sign In
              </FormButton>
              <TextLinkWrap>
                <a href="/forgot-password" style={{ textDecoration: "none" }}>
                  <Text>Forgot password</Text>
                </a>
              </TextLinkWrap>
              <Text>Or sign in with</Text>
              <SocialMediaWrap>
                <SocialIcons>
                  <SocialIconLink href={signUpUrl} aria-label="PhoneLogin">
                    <FaEnvelope />
                  </SocialIconLink>
                  <SocialIconLink
                    onClick={handleGoogleSignIn}
                    aria-label="Google"
                  >
                    <FaGoogle />
                  </SocialIconLink>
                  {/* <SocialIconLink
                    onClick={handleFbSignIn}
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink> */}
                  {/* <SocialIconLink
                    onClick={handleTwitterSignIn}
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </SocialIconLink> */}
                </SocialIcons>
              </SocialMediaWrap>
              <TextLinkWrap>
                <a href={signUpUrl} style={{ textDecoration: "none" }}>
                  <Text>New User? Sign up</Text>
                </a>
              </TextLinkWrap>
            </Form>
            <FooterInfo>Powered by Kehillah Global</FooterInfo>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
