import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { redirectCurrentUser } from "../helpers/authHelpers";
import AlreadyLoggedInPage from "./AlreadyLoggedInPage";

export default function Dashboard() {
  const [setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  if (currentUser) {
    redirectCurrentUser(currentUser);
  }

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to logout");
    }
  }

  async function verifyUser(e) {
    e.preventDefault();

    try {
      setError("");
      await currentUser
        .sendEmailVerification()
        .then(function () {
          alert("verification email sent");
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      let errorMsg = "Verification failed";
      const { message } = error;
      if (message){
        errorMsg = errorMsg + `: ${message}`
      }
      setError(errorMsg);
    }
  }
  return <AlreadyLoggedInPage logout={handleLogout} verifyUser={verifyUser} />;
}
