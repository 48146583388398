import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  min-height: 62px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: ${({ theme }) => theme.primaryColor};
  // background: url(${({ theme }) => theme.backgroundImage});
`;

export const Icon = styled(Link)`
  margin-top: 50px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: ${({ theme }) => theme.whiteText};
  font-weight: bold;
  font-size: 30px;

  &:hover {
    color: ${({ theme }) => theme.whiteText};
    cursor: pointer;
    font-size: 30.1px;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: 8px;
  }
`;

export const FormWrap = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: ${({ theme }) => theme.formColor};
  max-width: 400px;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 15px 32px;
  border-radius: 25px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 200px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 3px;
  font-size: 15px;
  color: ${({ theme }) => theme.darkText};
  font-weight: bold;
`;

export const FormInput = styled.input`
  padding: 5px 15px;
  margin-bottom: 15px;
  border: transparent;
  border-radius: 5px;
`;

export const FormButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  padding: 10px 0;
  border: none;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  color: ${({ theme }) => theme.whiteText};
  font-size: 15px;
  cursor: pointer;
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 2px;
  color: ${({ theme }) => theme.darkText};
  font-size: 15px;
`;

export const TextLinkWrap = styled.div`
  margin: 5px auto 0 auto;
`;
