import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import firebase from "firebase/app";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updateEmail(password);
  }

  function googleSignin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return auth
      .signInWithPopup(provider)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  }

  function fbSignin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return auth
      .signInWithPopup(provider)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  }

  function twitterSignin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return auth
      .signInWithPopup(provider)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  }

  function setupRecaptcha() {
    window.recaptachaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          sendOtpCode();
        },
      }
    );
  }

  function sendOtpCode(phoneNumber) {
    setupRecaptcha();
    const appVerifier = window.recaptachaVerifier;
    auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log("sms not sent", error);
      });
  }

  function verifyOtpCode(otp) {
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
        console.log(user, " is signed in");
      })
      .catch((error) => {
        console.log("verification failed", error);
      });
  }
  function sendCodeToPhone(phoneNumber) {
    phoneNumber = "+" + phoneNumber;
    sendOtpCode(phoneNumber);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser]);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    googleSignin,
    fbSignin,
    twitterSignin,
    sendCodeToPhone,
    verifyOtpCode,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
