/**
 * This file contains helper functions for this application
 */


/**
 * isEmpty returns true if val is undefined or null or the stringified versions
 * of those.
 */
export const isEmpty = (val) => {  
  if ([undefined, null, 'null', 0, 'undefined', ''].includes(val)){
    return true;
  }

  return false;
}

/**
 * 
 * @param {*} val 
 * @returns null if val is empty otherwise it returns val
 */
export const sanitizeValue = (val) => {  
  if (isEmpty(val)){
    return null;
  }

  return val;
}

/**
 * Returns a Javascript object from a string
 * @param {*} val 
 * @returns 
 */
export const parseJSON = (val) => {
  const sanitizedValue = sanitizeValue('' + val);
  if(!sanitizedValue) return {}
  try {
    return JSON.parse(sanitizedValue)
  } catch (error) {
    console.log(`JSON: Error parsing ${val} to JSON`)
    return {}
  }
}