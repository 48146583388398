import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { parseJSON } from "../helpers/utils";

export default function PrivateRoute({ component: Component, ...rest }) {
  useEffect(() => {
    const dataGotten = localStorage.dataGotten
      ? parseJSON(localStorage.dataGotten)
      : {
          appLogo:
            "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
          appName: "",
        };
    console.log(dataGotten);
  }, []);

  const { currentUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
}
