import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
  TextLinkWrap,
  SocialMediaWrap,
  SocialIconLink,
  SocialIcons,
  FooterInfo,
} from "./styles/SignUpElements";

import { FaGoogle, FaEnvelope } from "react-icons/fa";
import { redirectCurrentUser } from "../helpers/authHelpers";
import { parseJSON } from "../helpers/utils";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup, googleSignin } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const titleText = "Sign Up";
  const urlExtention = window.location.href.toString().split("?")[1]
  const history = useHistory();
  const comingData = parseJSON(localStorage.dataGotten);
  const emailLoginUrl = `/email-login/?${urlExtention}`
  const loginUrl = `/login/?${urlExtention}`


  const { currentUser } = useAuth();
  
  if (currentUser) {
    redirectCurrentUser(currentUser)
  }


  const handleRedirect = (loginRes, method) => {
    if (loginRes?.user) {
      const isSignIn = loginRes?.operationType === "signIn";
      const newReturnBody = {
        userName: loginRes?.user?.displayName,
        refreshToken: loginRes?.user?.za,
        email: loginRes?.user?.email,
        phoneNumber: loginRes?.user?.phoneNumber,
        signIn: isSignIn,
        signUp: !isSignIn,
        actionType: method,
      };

      let loginResults = JSON.stringify(newReturnBody);
      const encodedData = Buffer.from(loginResults, 'base64');
      let storage = parseJSON(localStorage.siteSettings);
      let redirectUrl = storage?.redirectTo;
      redirectUrl = `${redirectUrl}${encodedData}`;
      window.location.href = redirectUrl;
    } else {
      history.push("/");
    }
  };


  function checkPassword(str) {
    const pwd = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return pwd.test(str);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!checkPassword(passwordRef.current.value)) {
      return setError(
        "Password must be at least 8 characters long and must contain an Uppercase, a numeric and a special character"
      );
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      const loginRes = await signup(emailRef.current.value, passwordRef.current.value);
      handleRedirect(loginRes, "Email");
    } catch (error) {
      const { message } = error;
      setError(`Account creation failed: ${message}`);
    }

    setLoading(false);
  }

  async function handleGoogleSignIn(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const loginRes = await googleSignin();
      handleRedirect(loginRes, "Gmail");
    } catch (error) {
      setError("Google Sign In Failed");
    }

    setLoading(false);
  }

  // async function handleFbSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await fbSignin();
  //     handleRedirect(loginRes, "Facebook");
  //   } catch (error) {
  //     setError("Facebook Sign In Failed");
  //   }

  //   setLoading(false);
  // }

  // async function handleTwitterSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await twitterSignin();
  //     handleRedirect(loginRes, "Twitter");
  //   } catch (error) {
  //     setError("Twitter Sign In Failed");
  //   }

  //   setLoading(false);
  // }

  return (
    <>
      <Container>
        <Icon to="/">{comingData.appName}</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>{titleText}</FormH1>
              {error && <Text style={{color: "red"}}>{error}</Text>}
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput
                placeholder="Enter your email address"
                type="email"
                ref={emailRef}
                required
              />
              <FormLabel htmlFor="for">Password</FormLabel>
              <FormInput
                placeholder="Enter your Password"
                type="password"
                ref={passwordRef}
                required
              />
              <FormLabel htmlFor="for">Confirm Password</FormLabel>
              <FormInput
                placeholder="Re-enter Password"
                type="password"
                ref={passwordConfirmRef}
                required
              />
              <FormButton type="submit" disabled={loading}>
                Sign Up
              </FormButton>
              <Text>Or sign in with</Text>
              <SocialMediaWrap>
                <SocialIcons>
                  <SocialIconLink href={emailLoginUrl} aria-label="PhoneLogin">
                    <FaEnvelope />
                  </SocialIconLink>
                  <SocialIconLink
                    onClick={handleGoogleSignIn}
                    aria-label="Google"
                  >
                    <FaGoogle />
                  </SocialIconLink>
                  {/* <SocialIconLink
                    onClick={handleFbSignIn}
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink> */}
                  {/* <SocialIconLink
                    onClick={handleTwitterSignIn}
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </SocialIconLink> */}
                </SocialIcons>
              </SocialMediaWrap>
              <TextLinkWrap>
                <a href={loginUrl} style={{ textDecoration: "none" }}>
                  <Text>Already Have an account? Log in</Text>{" "}
                </a>
              </TextLinkWrap>
            </Form>
            <FooterInfo>Powered by Kehillah Global</FooterInfo>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
