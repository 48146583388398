import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {
  Container,
  FormWrap1,
  FormWrap2,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
  TextLinkWrap,
  SocialMediaWrap,
  SocialIconLink,
  SocialIcons,
  FooterInfo,
} from "./styles/TwoStepLoginElements";

import { FaFacebook, FaGoogle, FaPhone } from "react-icons/fa";
import { redirectCurrentUser } from "../helpers/authHelpers";
import { parseJSON } from "../helpers/utils";

export default function TwoStepLogin() {
  const { currentUser } = useAuth();

  if (currentUser) {
    redirectCurrentUser(currentUser);
  }

  const otpRef = useRef();
  const [showOtpBox, setShowOtpBox] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, googleSignin, fbSignin } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const titleText = "Email Login";
  const titleText2 = "Verify Phone";
  // eslint-disable-next-line
  const [appData, setAppData] = useState({
    appLogo:
      "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
    appName: "",
  });
  const history = useHistory();

  useEffect(() => {
    const dataGotten = localStorage.dataGotten
      ? parseJSON(localStorage.dataGotten)
      : {
          appLogo:
            "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
          appName: "",
        };
    setAppData(dataGotten);
  }, []);
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      if (login(emailRef.current.value, passwordRef.current.value)) {
        setShowOtpBox(true);
      }

      await login(emailRef.current.value, passwordRef.current.value);
    } catch {
      setError("Email or Password incorrect");
    }
    setLoading(false);
  }

  async function handleGoogleSignIn(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await googleSignin();
      history.push("/");
    } catch (error) {
      let errorMsg = "Google Sign In Failed"
      const { message } = error;
      if (message){
        errorMsg = errorMsg + `: ${message}`
      }
      setError(errorMsg);
    }

    setLoading(false);
  }

  async function handleFbSignIn(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await fbSignin();
      history.push("/");
    } catch (error) {
      setError("Facebook Sign In Failed");
    }

    setLoading(false);
  }

  // async function handleTwitterSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     await twitterSignin();
  //     history.push("/");
  //   } catch (error) {
  //     setError("Twitter Sign In Failed");
  //   }

  //   setLoading(false);
  // }

  return (
    <>
      <Container>
        <Icon to="/">Auth</Icon>
        {!showOtpBox ? (
          <FormWrap1>
            <FormContent>
              <Form onSubmit={handleSubmit}>
                <FormH1>{titleText}</FormH1>
                {error && <Text style={{color: 'red'}}>{error}</Text>}
                <FormLabel htmlFor="for">Email</FormLabel>
                <FormInput type="email" ref={emailRef} required />
                <FormLabel htmlFor="for">Password</FormLabel>
                <FormInput type="password" ref={passwordRef} required />
                <FormButton type="submit" disabled={loading}>
                  Continue
                </FormButton>
                <TextLinkWrap>
                  <a href="/forgot-password" style={{ textDecoration: "none" }}>
                    <Text>Forgot password</Text>
                  </a>
                </TextLinkWrap>
                <Text>Or sign in with</Text>
                <SocialMediaWrap>
                  <SocialIcons>
                    <SocialIconLink href="/login" aria-label="PhoneLogin">
                      <FaPhone />
                    </SocialIconLink>
                    <SocialIconLink
                      onClick={handleGoogleSignIn}
                      aria-label="Google"
                    >
                      <FaGoogle />
                    </SocialIconLink>
                    <SocialIconLink
                      onClick={handleFbSignIn}
                      aria-label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLink>
                    {/* <SocialIconLink
                      onClick={handleTwitterSignIn}
                      aria-label="Twitter"
                    >
                      <FaTwitter />
                    </SocialIconLink> */}
                  </SocialIcons>
                </SocialMediaWrap>
                <TextLinkWrap>
                  <a href="/signup" style={{ textDecoration: "none" }}>
                    <Text>New User? Sign up</Text>
                  </a>
                </TextLinkWrap>
              </Form>
              <FooterInfo>Powered by Kehillah Global</FooterInfo>
            </FormContent>
          </FormWrap1>
        ) : (
          <FormWrap2>
            <FormContent>
              <Form>
                <FormH1>{titleText2}</FormH1>
                {error && <Text style={{color: 'red'}}>{error}</Text>}
                <Text>
                  A verification code has been sent to your phone, please enter
                  it below
                </Text>
                <FormLabel> </FormLabel>
                <FormInput
                  placeholder="Please enter the code sent to your phone"
                  ref={otpRef}
                  required
                />
                <FormButton type="submit" disabled={loading}>
                  Continue
                </FormButton>
                <TextLinkWrap>
                  <a href="." style={{ textDecoration: "none" }}>
                    <Text>Resend Code</Text>
                  </a>
                </TextLinkWrap>
              </Form>
              <FooterInfo>Powered by Kehillah Global</FooterInfo>
            </FormContent>
          </FormWrap2>
        )}
      </Container>
    </>
  );
}
