import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
} from "./styles/UpdateProfileElements";
import { parseJSON } from "../helpers/utils";

export default function PasswordReset() {
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const dataGotten = localStorage.dataGotten
      ? parseJSON(localStorage.dataGotten)
      : {
          appLogo:
            "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
          appName: "",
        };
    console.log(dataGotten);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (passwordRef.current.value !== currentUser.password) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/email-login");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Container>
        <Icon to="/">Auth</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Set a New Password</FormH1>
              {error && <Text style={{color: 'red'}}>{error}</Text>}
              {!error ? (
                <Text>
                  Passwords must be at least <b>8 characters long</b>, and must
                  contain at least <b>an uppercase letter</b>, <b>a number</b>{" "}
                  and <b>a special character</b>
                </Text>
              ) : null}
              <FormLabel htmlFor="for">New Password</FormLabel>
              <FormInput type="password" ref={passwordRef} required />

              <FormLabel htmlFor="for">Re-enter Password</FormLabel>
              <FormInput type="password" ref={passwordConfirmRef} required />
              <FormButton type="submit" disabled={loading}>
                Continue
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
