import React, { useRef, useState, useEffect } from "react";
import { auth } from "../firebase";
import { parseJSON } from "../helpers/utils";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
  TextLinkWrap,
  FooterInfo,
} from "./styles/ForgotPasswordElements";

export default function ForgotPassword() {
  const emailRef = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dataGotten = localStorage.dataGotten
      ? parseJSON(localStorage.dataGotten)
      : {
          appLogo:
            "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
          appName: "",
        };
    console.log(dataGotten);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await auth.sendPasswordResetEmail(emailRef.current.value);
      setMessage("A link has been sent to your email to reset your password");
    } catch (error) {
      let errorMsg = "Failed to Reset Password"
      const { message } = error;
      if (message){
        errorMsg = errorMsg + `: ${message}`
      }
      setError(errorMsg);
    }

    setLoading(false);
  }

  return (
    <>
      <Container>
        <Icon to="/">Auth</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1> Reset Password</FormH1>
              {error && <Text style={{color: 'red'}}>{error}</Text>}
              {message && <Text style={{color: 'green'}}>{message}</Text>}
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput
                placeholder="Enter your email address"
                type="email"
                ref={emailRef}
                required
              />
              <FormButton type="submit" disabled={loading}>
                Reset
              </FormButton>
              <TextLinkWrap>
                <a href="/login" style={{ textDecoration: "none" }}>
                  <Text>Back to Login</Text>
                </a>
              </TextLinkWrap>
            </Form>
            <FooterInfo>Powered by Kehillah Global</FooterInfo>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
