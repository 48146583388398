import { parseJSON, sanitizeValue } from "../../helpers/utils";
var qs = require('qs');

let colorData = {};

let { entry } = qs.parse(window.location.href.toString());
// sanitize the entry value in case something like undefined comes through
entry = sanitizeValue(entry)

if (entry) {
  let decodedSiteSettings = {};
  decodedSiteSettings = parseJSON(Buffer.from(entry, 'base64'));
  localStorage.siteSettings = JSON.stringify(decodedSiteSettings);
  if (decodedSiteSettings?.theme) {
    colorData = decodedSiteSettings.theme;
  } else {
    colorData = {
      primaryColor: "#220447",
      formColor: "#f9f9f9",
      whiteText: "#f9f9f9",
      darkText: "#010101",
      font: "'Karla', sans-serif",
    };
  }
} else {
  localStorage.siteSettings = JSON.stringify({ appName: "Auth" });
  colorData = {
    primaryColor: "#220447",
    formColor: "#f9f9f9",
    whiteText: "#f9f9f9",
    darkText: "#010101",
    font: "'Karla', sans-serif",
  };
}

export const theme = {
  primaryColor: colorData.primaryColor,
  formColor: colorData.formColor,
  whiteText: colorData.whiteText,
  darkText: colorData.darkText,
  font: colorData.font,
  backgroundImage: require("../../assets/bgone.jpg").default,
};
