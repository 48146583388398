import React, { useState, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormInput,
  FormButton,
  FormH1,
  FormLabel,
  Text,
  TextLinkWrap,
  SocialMediaWrap,
  SocialIconLink,
  SocialIcons,
  FooterInfo,
} from "./styles/LoginElements";

import { FaGoogle, FaEnvelope } from "react-icons/fa";
import { redirectCurrentUser } from "../helpers/authHelpers";
import { parseJSON } from "../helpers/utils";


export default function PhoneNumberLogin() {

  const { currentUser } = useAuth();
  
  if (currentUser) {
    redirectCurrentUser(currentUser)
  }

  const {
    sendCodeToPhone,
    verifyOtpCode,
    googleSignin,
  } = useAuth();
  const [phoneRef, setPhoneRef] = useState();
  const otpRef = useRef();
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const titleText = "Phone Login";
  const comingData = parseJSON(localStorage.dataGotten);
  // eslint-disable-next-line no-unused-vars
  const [apiUrl, setApiUrl] = useState("");
  const history = useHistory();
  const urlExtention = window.location.href.toString().split("?")[1]

  const emailLoginUrl = `/email-login/?${urlExtention}`
  const signUpUrl = `/signup/?${urlExtention}`

  
  const handleRedirect = (loginRes, method) => {
    /////// CHECK LATIN NUMBERS ///////////////
    const checkIfNameHasOthercharacters = (userName) => {
      let returnValue = "";
      const dividedNames = userName.split(" ");
      dividedNames.forEach((name) => {
        if (/[^\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF]/.test(name)) {

        } else {
          returnValue += `${name} `;
        }
      })
      return returnValue;
    }
    ////////////////////////////////
    if (loginRes.user) {
      
      const isSignIn = loginRes.operationType === "signIn";
      const newReturnBody = {
        userName: checkIfNameHasOthercharacters(loginRes.user.displayName),
        refreshToken: loginRes.user.za,
        email: loginRes.user.email ? loginRes.user.email : "",
        phoneNumber: loginRes.user.phoneNumber ? loginRes.user.phoneNumber : "",
        signIn: isSignIn,
        signUp: !isSignIn,
        actionType: method,
      };

      let loginResults = JSON.stringify(newReturnBody);

      const encodedData = btoa(loginResults);
      let storage = parseJSON(localStorage.dataGotten);
      let redirectUrl = storage.redirectTo;
      redirectUrl = `${redirectUrl}${encodedData}`;
      window.location.href = redirectUrl;
    } else {
      history.push("/");
    }
  };

  function handleSubmit() {
    try {
      setError("");
      setLoading(true);
      sendCodeToPhone(phoneRef);
    } catch (error) {
      let errorMsg = "sms not sent"
      const { message } = error;
      if (message){
        errorMsg = errorMsg + `: ${message}`
      }
      setError(errorMsg);
    }
    setLoading(false);
  }

  function onOtpRequest(e) {
    if (!phoneRef) {
      setError("Please enter your phone number to continue");
    } else {
      e.preventDefault();
      phoneRef ? setShowOtpBox(true) : setShowOtpBox(false);
      handleSubmit();
    }
  }

 async function handleOtp(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await verifyOtpCode(otpRef.current.value);
      // handleRedirect(loginRes, "Phone");
    } catch {
      setError(
        "Login Failed. Please reload and enter your phone number to try again"
      );
    }
    setLoading(false);
  }

  // if (currentUser) {
  //   history.push("/");
  // }

  async function handleGoogleSignIn(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const loginRes = await googleSignin();
      handleRedirect(loginRes, "Gmail");
    } catch (error) {
      console.log(error);
      setError("Google Sign in Failed");
    }

    setLoading(false);
  }

  // async function handleFbSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await fbSignin();
  //     handleRedirect(loginRes, "Facebook");
  //   } catch (error) {
  //     console.log(error);
  //     setError("Facebook Sign in Failed");
  //   }

  //   setLoading(false);
  // }

  // async function handleTwitterSignIn(e) {
  //   e.preventDefault();

  //   try {
  //     setError("");
  //     setLoading(true);
  //     const loginRes = await twitterSignin();
  //     handleRedirect(loginRes, "Twitter");
  //   } catch (error) {
  //     console.log(error);
  //     setError("Twitter Sign in Failed");
  //   }

  //   setLoading(false);
  // }

  return (
    <>
      <Container>
        <Icon to="/">{comingData.appName}</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleOtp}>
              <div id="recaptcha-container"></div>
              <FormH1>{titleText}</FormH1>
              {error && <Text style={{color: 'red'}}>{error}</Text>}
              <FormLabel htmlFor="for">Phone Number</FormLabel>
              <div>
                <PhoneInput
                  id="phone-number"
                  inputStyle={{
                    width: "100%",
                    marginBottom: "15px",
                    borderRadius: "5px",
                  }}
                  country={"gh"}
                  value={phoneRef}
                  onChange={setPhoneRef}
                />
              </div>
              {showOtpBox ? (
                <FormInput
                  placeholder="Please enter the code sent to your phone"
                  ref={otpRef}
                  required
                />
              ) : null}

              {showOtpBox ? (
                <FormButton type="submit" disabled={loading}>
                  Verify
                </FormButton>
              ) : (
                <FormButton
                  onClick={onOtpRequest}
                  type="submit"
                  disabled={loading}
                >
                  Log in
                </FormButton>
              )}
              <Text>Or sign in with</Text>
              <SocialMediaWrap>
                <SocialIcons>
                  <SocialIconLink href={emailLoginUrl}  aria-label="PhoneLogin">
                    <FaEnvelope />
                  </SocialIconLink>
                  <SocialIconLink
                    onClick={handleGoogleSignIn}
                    aria-label="Google"
                  >
                    <FaGoogle />
                  </SocialIconLink>
                  {/* <SocialIconLink
                    onClick={handleFbSignIn}
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink> */}
                  {/* <SocialIconLink
                    onClick={handleTwitterSignIn}
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </SocialIconLink> */}
                </SocialIcons>
              </SocialMediaWrap>
              <TextLinkWrap>
                <a href={signUpUrl} style={{ textDecoration: "none" }}>
                  <Text>New User? Sign up</Text>
                </a>
              </TextLinkWrap>
            </Form>
            <FooterInfo>Powered by Kehillah Global</FooterInfo>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
