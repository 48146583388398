import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormButton,
  FormH1,
  FormInput,
  FormLabel,
  Text,
  TextLinkWrap,
} from "./styles/UpdateProfileElements";

import { redirectCurrentUser } from "../helpers/authHelpers";
import { parseJSON } from "../helpers/utils";


export default function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
    
  if (currentUser) {
    redirectCurrentUser(currentUser)
  }

  useEffect(() => {
    const dataGotten = localStorage.dataGotten
      ? parseJSON(localStorage.dataGotten)
      : {
          appLogo:
            "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png",
          appName: "",
        };
    console.log(dataGotten);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value !== currentUser.password) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Container>
        <Icon to="/">Auth</Icon>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Update Profile</FormH1>
              {error && <Text style={{color: 'red'}}>{error}</Text>}
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
              />
              <FormLabel htmlFor="for">Password</FormLabel>
              <FormInput
                type="password"
                ref={passwordRef}
                placeholder="Leave blank if you don't want to change"
              />
              <FormLabel htmlFor="for">Confirm Password</FormLabel>
              <FormInput
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank if you don't want to change"
              />
              <FormButton type="submit" disabled={loading}>
                Continue
              </FormButton>
              <TextLinkWrap>
                <a href="/" style={{ textDecoration: "none" }}>
                  <Text>Cancel</Text>
                </a>
              </TextLinkWrap>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
